import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import People from "@material-ui/icons/People";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import Warning from "@material-ui/icons/Warning";

import Info from "@material-ui/icons/Info";
import Face from "@material-ui/icons/Face";
import Build from "@material-ui/icons/Build";
import BugReport from "@material-ui/icons/BugReport";

import Check from "@material-ui/icons/Check";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ContactTabs from "components/CustomTabs/ContactTabs.jsx";

import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// styles
import basicsStyle from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class SectionContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      organization: "",
      email: "",
      phone: "",
      message: "",
      selectedProduct: "",
      selectedPlatform: "",
      validated: false,
      sendStatus: "WAITING"
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handlePlatformChange = this.handlePlatformChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.clearSendState = this.clearSendState.bind(this);
  }
  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }
  handleOrganizationChange(event) {
    this.setState({ organization: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  handlePhoneChange(event) {
    this.setState({ phone: event.target.value });
  }
  handleMessageChange(event) {
    this.setState({ message: event.target.value });
  }
  handleProductChange(event) {
    this.setState({ selectedProduct: event.target.value });
  }
  handlePlatformChange(event) {
    this.setState({ selectedPlatform: event.target.value });
  }

  validateName() {
    return this.state.name.length !== 0;
  }

  validateOrganization() {
    return this.state.organization.length !== 0;
  }

  validateEmail() {
    return this.state.email.length !== 0;
  }

  validateMessage() {
    return this.state.message.length !== 0;
  }

  submitForm(ev) {
    // Prevent submitting the form if validation fails below.
    ev.preventDefault();

    // Form element must have this: onSubmit={this.submitForm}
    console.log("validating form")
    this.setState({ sendStatus: "VALIDATING" });
    let errorDesc;
    if (!this.validateName()) {
      errorDesc = "My name";
    } else if (!this.validateOrganization()) {
      errorDesc = "Company or organization";
    } else if (!this.validateEmail()) {
      errorDesc = "Email address";
    } else if (!this.validateMessage()) {
      errorDesc = "Message";
    }
    if (errorDesc !== undefined) {
      alert("Required field '" + errorDesc + "' is empty!");
      this.setState({ validated: true });
      this.setState({ sendStatus: "WAITING" });
      return false;
    }
    //this.setState({ validated: false });

    console.log("sending form")
    this.setState({ sendStatus: "SENDING" });
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ sendStatus: "SUCCESS" });
        console.log("Form status: SUCCESS");
        window.setTimeout(this.clearSendState, 4000);
      } else {
        this.setState({ sendStatus: "ERROR" });
        console.log("Form status: ERROR " + xhr.status);
        window.setTimeout(this.clearSendState, 4000);
      }
    };
    xhr.send(data);
  }

  clearSendState() {
    this.setState({ sendStatus: "WAITING" });
  }

  render() {
    const { classes } = this.props;
    const { sendStatus } = this.state;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div id="tabs">
            <ContactTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Info",
                  tabIcon: Info,
                  tabContent: (
                    <div id="inputs">
                      <div className={classes.title}>
                        <h3>Select the most relevant tab from above</h3>
                        <br />
                        <h5>
                          We will direct your message to the correct person and
                          you will get an answer faster. In case of a support
                          request or a bug report, try to answer all questions.
                          It helps in solving the issue.</h5>
                      </div>
                    </div>
                  ),
                },
                {
                  tabName: "Get in touch",
                  tabRecaptcha: "recaptcha-1",
                  tabIcon: Face,
                  tabContent: (
                    <form onSubmit={this.submitForm} method="POST"
                      action="https://formspree.io/f/xqkgnkwy">
                      <div id="inputs">
                        <div className={classes.title}>
                          <h3>Contact:</h3>
                        </div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={8} lg={8}>
                            <CustomInput
                              labelText="My name"
                              id="form-name"
                              name="Name"
                              value={this.state.name}
                              onChange={this.handleNameChange}
                              error={this.state.validated && !this.validateName()}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <People />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Company or organization"
                              id="form-organization"
                              name="Organization"
                              value={this.state.organization}
                              onChange={this.handleOrganizationChange}
                              error={this.state.validated && !this.validateOrganization()}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <BusinessCenter />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={8} lg={8}>
                            <CustomInput
                              labelText="Email address"
                              id="form-email"
                              name="Email"
                              value={this.state.email}
                              onChange={this.handleEmailChange}
                              error={this.state.validated && !this.validateEmail()}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Email />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Phone number (optional)"
                              id="form-phone"
                              name="Phone"
                              value={this.state.phone}
                              onChange={this.handlePhoneChange}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Phone />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <div className={classes.title}>
                          <h3>Message:</h3>
                        </div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              id="form-message"
                              name="Message"
                              value={this.state.message}
                              onChange={this.handleMessageChange}
                              error={this.state.validated && !this.validateMessage()}
                              label=""
                              multiline
                              rows="8"
                              variant="outlined"
                              fullWidth
                            />
                          </GridItem>
                        </GridContainer>
                        <br />
                        <div id="recaptcha-1"></div>
                        <div align="right">
                          {sendStatus === "WAITING" &&
                            <Button round size="lg" color="success" type="submit">
                              <Email className={classes.icons} />Send
                            </Button>
                          }
                          {sendStatus === "SUCCESS" && <SnackbarContent
                            message={
                              <span>
                                <b>MESSAGE SENT.</b> Thank you. We will get back to you.
                                </span>
                            }
                            color="success"
                            icon={Check}
                          />
                          }
                          {sendStatus === "ERROR" &&
                            <SnackbarContent
                              message={
                                <span>
                                  <b>ERROR: </b>
                                    How unfortunate! Please try again, or contact us
                                    via email to <i>info [at] finwe.fi</i>
                                </span>
                              }
                              color="danger"
                              icon={Warning}
                            />
                          }
                        </div>
                      </div>
                    </form>
                  )
                },
                {
                  tabName: "Support Request",
                  tabRecaptcha: "recaptcha-2",
                  tabIcon: Build,
                  tabContent: (
                    <form onSubmit={this.submitForm} method="POST"
                      action="https://formspree.io/f/mgepdolk">
                      <div id="inputs">
                        <div className={classes.title}>
                          <h3>Contact:</h3>
                        </div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={8} lg={8}>
                            <CustomInput
                              labelText="My name"
                              id="form-name"
                              name="Name"
                              value={this.state.name}
                              onChange={this.handleNameChange}
                              error={this.state.validated && !this.validateName()}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <People />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Company or organization"
                              id="form-organization"
                              name="Organization"
                              value={this.state.organization}
                              onChange={this.handleOrganizationChange}
                              error={this.state.validated && !this.validateOrganization()}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <BusinessCenter />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={8} lg={8}>
                            <CustomInput
                              labelText="Email address"
                              id="form-email"
                              name="Email"
                              value={this.state.email}
                              onChange={this.handleEmailChange}
                              error={this.state.validated && !this.validateEmail()}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Email />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Phone number (optional)"
                              id="form-phone"
                              name="Phone"
                              value={this.state.phone}
                              onChange={this.handlePhoneChange}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Phone />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            <div className={classes.title}>
                              <h3>Product:</h3>
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedProduct === "LiveSYNC Camera & Manager"}
                                    onChange={this.handleProductChange}
                                    value="LiveSYNC Camera & Manager"
                                    name="Product"
                                    aria-label="A"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="LiveSYNC Camera & Manager"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedProduct === "LiveSYNC Presentation Solution"}
                                    onChange={this.handleProductChange}
                                    value="LiveSYNC Presentation Solution"
                                    name="Product"
                                    aria-label="B"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="LiveSYNC Presentation Solution"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedProduct === "Orion360 SDK"}
                                    onChange={this.handleProductChange}
                                    value="Orion360 SDK"
                                    name="Product"
                                    aria-label="C"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Orion360 SDK"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedProduct === "Other"}
                                    onChange={this.handleProductChange}
                                    value="Other"
                                    name="Product"
                                    aria-label="D"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Other"
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            <div className={classes.title}>
                              <h3>Platform:</h3>
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedPlatform === "Web Browser / Cloud"}
                                    onChange={this.handlePlatformChange}
                                    value="Web Browser / Cloud"
                                    name="Platform"
                                    aria-label="A"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Web Browser / Cloud"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedPlatform === "Android"}
                                    onChange={this.handlePlatformChange}
                                    value="Android"
                                    name="Platform"
                                    aria-label="B"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Android"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedPlatform === "iOS"}
                                    onChange={this.handlePlatformChange}
                                    value="iOS"
                                    name="Platform"
                                    aria-label="C"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="iOS"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedPlatform === "Other"}
                                    onChange={this.handlePlatformChange}
                                    value="Other"
                                    name="Platform"
                                    aria-label="D"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Other"
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                        <div className={classes.title}>
                            <h3>Message:</h3>
                          </div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              id="form-message"
                              name="Message"
                              value={this.state.message}
                              onChange={this.handleMessageChange}
                              error={this.state.validated && !this.validateMessage()}
                              label=""
                              multiline
                              rows="8"
                              variant="outlined"
                              fullWidth
                            />
                          </GridItem>
                        </GridContainer>
                        <br />
                        <div id="recaptcha-2"></div>
                        <div align="right">
                          {sendStatus === "WAITING" &&
                            <Button round size="lg" color="success" type="submit">
                              <Email className={classes.icons} />Send
                          </Button>
                          }
                          {sendStatus === "SUCCESS" && <SnackbarContent
                            message={
                              <span>
                                <b>MESSAGE SENT.</b> Thank you. We will get back to you.
                              </span>
                            }
                            color="success"
                            icon={Check}
                          />
                          }
                          {sendStatus === "ERROR" &&
                            <SnackbarContent
                              message={
                                <span>
                                  <b>ERROR: </b>
                                  How unfortunate! Please try again, or contact us
                                  via email to <i>support [at] finwe.fi</i>
                                </span>
                              }
                              color="danger"
                              icon={Warning}
                            />
                          }
                        </div>
                      </div>
                    </form>
                  )
                },
                {
                  tabName: "Bug Report",
                  tabRecaptcha: "recaptcha-3",
                  tabIcon: BugReport,
                  tabContent: (
                    <form onSubmit={this.submitForm} method="POST"
                      action="https://formspree.io/f/mpzobqjr">
                      <div id="inputs">
                        <div className={classes.title}>
                          <h3>Contact:</h3>
                        </div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={8} lg={8}>
                            <CustomInput
                              labelText="My name"
                              id="form-name"
                              name="Name"
                              value={this.state.name}
                              onChange={this.handleNameChange}
                              error={this.state.validated && !this.validateName()}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <People />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Company or organization"
                              id="form-organization"
                              name="Organization"
                              value={this.state.organization}
                              onChange={this.handleOrganizationChange}
                              error={this.state.validated && !this.validateOrganization()}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <BusinessCenter />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={8} lg={8}>
                            <CustomInput
                              labelText="Email address"
                              id="form-email"
                              name="Email"
                              value={this.state.email}
                              onChange={this.handleEmailChange}
                              error={this.state.validated && !this.validateEmail()}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Email />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Phone number (optional)"
                              id="form-phone"
                              name="Phone"
                              value={this.state.phone}
                              onChange={this.handlePhoneChange}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Phone />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            <div className={classes.title}>
                              <h3>Product:</h3>
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedProduct === "LiveSYNC Camera & Manager"}
                                    onChange={this.handleProductChange}
                                    value="LiveSYNC Camera & Manager"
                                    name="Product"
                                    aria-label="A"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="LiveSYNC Camera & Manager"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedProduct === "LiveSYNC Presentation Solution"}
                                    onChange={this.handleProductChange}
                                    value="LiveSYNC Presentation Solution"
                                    name="Product"
                                    aria-label="B"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="LiveSYNC Presentation Solution"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedProduct === "Orion360 SDK"}
                                    onChange={this.handleProductChange}
                                    value="Orion360 SDK"
                                    name="Product"
                                    aria-label="C"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Orion360 SDK"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedProduct === "Other"}
                                    onChange={this.handleProductChange}
                                    value="Other"
                                    name="Product"
                                    aria-label="D"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Other"
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            <div className={classes.title}>
                              <h3>Platform:</h3>
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedPlatform === "Web Browser / Cloud"}
                                    onChange={this.handlePlatformChange}
                                    value="Web Browser / Cloud"
                                    name="Platform"
                                    aria-label="A"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Web Browser / Cloud"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedPlatform === "Android"}
                                    onChange={this.handlePlatformChange}
                                    value="Android"
                                    name="Platform"
                                    aria-label="B"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Android"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedPlatform === "iOS"}
                                    onChange={this.handlePlatformChange}
                                    value="iOS"
                                    name="Platform"
                                    aria-label="C"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="iOS"
                              />
                            </div>
                            <div className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={this.state.selectedPlatform === "Other"}
                                    onChange={this.handlePlatformChange}
                                    value="Other"
                                    name="Platform"
                                    aria-label="D"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label="Other"
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                        <div className={classes.title}>
                          <h3>Details:</h3>
                        </div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <CustomInput
                              labelText="What happened?"
                              id="material"
                              name="What happened"
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <CustomInput
                              labelText="Expected result?"
                              id="material"
                              name="Expected result"
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <CustomInput
                              labelText="Actual result?"
                              id="material"
                              name="Actual result"
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <div className={classes.title}>
                          <h3>Message to developers:</h3>
                        </div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              id="form-message"
                              name="Message"
                              value={this.state.message}
                              onChange={this.handleMessageChange}
                              error={this.state.validated && !this.validateMessage()}
                              label=""                              
                              multiline
                              rows="8"
                              variant="outlined"
                              fullWidth
                            />
                          </GridItem>
                          <GridItem xs={9} sm={9} md={9} lg={9}>
                          </GridItem>
                          <GridItem xs={3} sm={3} md={3} lg={3}>
                          </GridItem>
                        </GridContainer>
                        <br />
                        <div id="recaptcha-3"></div>
                        <div align="right">
                          {sendStatus === "WAITING" &&
                            <Button round size="lg" color="success" type="submit">
                              <Email className={classes.icons} />Send
                          </Button>
                          }
                          {sendStatus === "SUCCESS" && <SnackbarContent
                            message={
                              <span>
                                <b>MESSAGE SENT.</b> Thank you. We will get back to you.
                              </span>
                            }
                            color="success"
                            icon={Check}
                          />
                          }
                          {sendStatus === "ERROR" &&
                            <SnackbarContent
                              message={
                                <span>
                                  <b>ERROR: </b>
                                  How unfortunate! Please try again, or contact us
                                  via email to <i>support [at] finwe.fi</i>
                                </span>
                              }
                              color="danger"
                              icon={Warning}
                            />
                          }
                        </div>
                      </div>
                    </form>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(SectionContact);
