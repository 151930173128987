import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Icon from "@material-ui/core/Icon";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import customTabsStyle from "assets/jss/material-kit-react/components/customTabsStyle.jsx";

// Flag indicating if Google Recaptcha library has been loaded.
let recaptchaLoaded = false;

// Set callback function that will be called when Recaptcha library finishes loading.
if (typeof window !== "undefined") {
  window.onloadCallback = onloadCallback;
}

// Function to be called when Recaptcha library has been loaded.
export function onloadCallback() {
  console.log("Recaptcha loaded");
  recaptchaLoaded = true;
}

class CustomTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value !== prevState.value) {
      // Tab was changed. If selected tab's definition contains Recaptcha 
      // placeholder element ID, then try to add Recaptcha to that element.
      let elementId = this.props.tabs[this.state.value].tabRecaptcha;
      if (elementId !== undefined) {
        this.addRecaptcha(elementId, false);
      }
    }
  }

  addRecaptcha(elementId, startPolling) {
    //console.log("addRecaptcha");
    if (!recaptchaLoaded) {
      console.error("Recaptcha library not loaded!");
      if (startPolling) {
        window.setTimeout(this.addRecaptcha(elementId, startPolling), 1000);
      }
    } else {
      // Try to add recaptcha to the DOM.
      var element = document.getElementById(elementId);
      if (element === null) {
        console.log("Recaptcha placeholder " + elementId + " not found!");
        if (startPolling) {
          window.setTimeout(this.addRecaptcha(elementId, startPolling), 1000);
        }
      } else {
        console.log("Recaptcha placeholder " + elementId + 
          " found -> rendering Recaptcha!");
        try {
          window.grecaptcha.render(element, {
            'sitekey': '6LewgTEaAAAAAKAIoMO9edSpvcDqx7rPewRv4FnT',
          });
        } catch (error) {
          console.log("Recaptcha already renderer?");
        }
      }
    }
  }

  render() {
    const {
      classes,
      headerColor,
      plainTabs,
      tabs,
      title,
      rtlActive
    } = this.props;
    const cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleRTL]: rtlActive
    });
    return (
      <Card plain={plainTabs}>
        <CardHeader color={headerColor} plain={plainTabs}>
          {title !== undefined ? (
            <div className={cardTitle}>{title}</div>
          ) : null}
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayNone
            }}
            variant="fullWidth"
            scrollButtons="on"
          >
            {tabs.map((prop, key) => {
              var icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon:
                    typeof prop.tabIcon === "string" ? (
                      <Icon>{prop.tabIcon}</Icon>
                    ) : (
                        <prop.tabIcon />
                      )
                };
              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    labelContainer: classes.tabLabelContainer,
                    label: classes.tabLabel,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })}
          </Tabs>
        </CardHeader>
        <CardBody>
          {tabs.map((prop, key) => {
            if (key === this.state.value) {
              return <div key={key}>{prop.tabContent}</div>;
            }
            return null;
          })}
        </CardBody>
      </Card>
    );
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabRecaptcha: PropTypes.string,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};

export default withStyles(customTabsStyle)(CustomTabs);
